module.exports = {
  siteTitle: "Reality Quest | An XR Podcast", // <title>
  shortSiteTitle: "Reality Quest", // <title> ending for posts and pages
  siteDescription:
    "We are two curious humans on a journey to explore the wild west that is Immersive Technology (otherwise known as the XR industry).",
  siteUrl: "https://realityquestpodcast.com",
  // pathPrefix: "",
  siteImage: "og-preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Kelly Campelia & Jay Silvas",
  authorTwitterAccount: "jaysilvas",

  /* info */
  headerTitle: "Reality Quest",
  headerSubTitle: "Exploring the Future of the Metaverse",

  /* manifest.json */
  manifestName: "Reality Quest - An XR Podcast",
  manifestShortName: "Reality Quest", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/jsilvas" },
    { name: "twitter", url: "https://twitter.com/jaysilvas" },
    { name: "facebook", url: "http://facebook.com/jaysilvas" }
  ]
};
